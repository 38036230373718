html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #f3f4f5;
}

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  /* Уменьшение шрифта */
}

td,
th {
  padding: 4px;
  /* Уменьшение отступов внутри ячеек */
  border: none;
  /* Убираем границы ячеек */
  box-sizing: border-box;
  word-wrap: break-word;
}

@media screen and (max-width: 600px) {
  thead {
    display: none;
  }

  td {
    display: block;
    width: 100%;
    /* padding: 4px 0; */
    /* Уменьшаем вертикальные отступы на мобильных устройствах */
  }

  tr {
    display: block;
    /* margin-bottom: 15px; */
  }

  td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
}